body {
}


.App {
  text-align: center;
  padding: 2%;
}


.input-serial {

}

.icon-submit {
  color: #1890ff !important;
}

.div-button {
  margin-top: 10%;
  display: block;
  padding: 10px;
}

.router-button {
  margin: 1px;
  display: block;
}
